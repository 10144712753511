@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";

@import "@/fonts/gilroy/400.css";
@import "@/fonts/gilroy/600.css";

:root {
  --scrollBarWidth: 0;
}

body {
  margin: 0;
  font-family: "Gilroy", "Roboto", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  font-size: 10px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-size: inherit;
  margin: 0;
  padding: 0;
}

.stop-scrolling {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-right: var(--scrollBarWidth);
}

#WEB3_CONNECT_MODAL_ID {
  position: absolute;
  z-index: 22;
}
